import { CSSProperties, FC, ReactElement, useMemo } from 'react';

import classNames from 'classnames';
import { motion } from 'framer-motion';
import { Translate } from 'next-translate';

import { withTranslation } from '@lib/i18n';

import ScrollableContainer from '../ScrollableContainer';

import { formatValueToString } from './utils';

import './index.scss';

type QueryTableProps = {
    tableData: {
        fields: string[];
        rows: Record<string, unknown>[];
    };
    maxLines?: number;
    emptyTableNode?: ReactElement;
    className?: string;
    scrollbarsClassName?: string;
    tableHeight?: number | string;
    showRowNumber?: boolean;
    allowLineBreak?: boolean;
    t: Translate;
    animateRowsReplacement?: boolean;
    getTableCellStyle?: (params: {
        row: object;
        field: string;
        index: number;
    }) => CSSProperties;
};

const QueryTable: FC<QueryTableProps> = (props) => {
    const {
        tableData = {
            fields: [],
            rows: [],
        },
        maxLines = 6,
        emptyTableNode,
        className,
        scrollbarsClassName,
        tableHeight,
        showRowNumber = false,
        allowLineBreak = false,
        animateRowsReplacement,
        t,
        getTableCellStyle,
    } = props;

    const fields = useMemo(() => {
        if (!tableData.fields) return [];

        const fields: ReactElement[] = [];

        if (showRowNumber) {
            fields.push(<th key="rowNumber" className="table-row-number"></th>);
        }

        fields.push(
            ...tableData.fields.map((field, index) => <th key={index}>{field}</th>),
        );

        return fields;
    }, [showRowNumber, tableData.fields]);

    const data = useMemo(() => {
        if (!tableData.rows) return [];

        const uniqueRowsCounter = {};

        return tableData.rows.map((row, rowIndex) => {
            const children = (
                <>
                    {showRowNumber && (
                        <td className="table-row-number">{rowIndex + 1}</td>
                    )}

                    {tableData.fields.map((field, index) => (
                        <td
                            key={index}
                            style={getTableCellStyle?.({ row, field, index: rowIndex })}
                        >
                            {formatValueToString(row[field])}
                        </td>
                    ))}
                </>
            );

            if (animateRowsReplacement) {
                const key = tableData.fields.reduce(
                    (acc, field) => `${acc}${field};${row[field]};`,
                    '',
                );

                if (uniqueRowsCounter[key]) {
                    uniqueRowsCounter[key]++;
                } else {
                    uniqueRowsCounter[key] = 1;
                }

                const uniqueRowIndex = uniqueRowsCounter[key];

                return (
                    <motion.tr layout key={`${uniqueRowIndex};${key}`}>
                        {children}
                    </motion.tr>
                );
            }

            return <tr key={rowIndex}>{children}</tr>;
        });
    }, [
        animateRowsReplacement,
        getTableCellStyle,
        showRowNumber,
        tableData.fields,
        tableData.rows,
    ]);

    const isTableEmpty = useMemo(() => !fields.length || !data.length, [fields, data]);

    const height = tableHeight || 38 * (1 + Math.min(maxLines, data.length));

    return !isTableEmpty ? (
        <ScrollableContainer
            universal
            autoHide
            style={{ height }}
            className={classNames('query-table-scrollbars', scrollbarsClassName)}
        >
            <div
                className={classNames([
                    'result-table',
                    { 'line-break': allowLineBreak },
                    className,
                ])}
            >
                <table>
                    <thead>
                        <tr>{fields}</tr>
                    </thead>

                    <tbody>{data}</tbody>
                </table>
            </div>
        </ScrollableContainer>
    ) : (
        emptyTableNode || <div className="no-data">{t('queryTable.noData')}</div>
    );
};

export default withTranslation('common')(QueryTable);
