import { LandingSectionTitle } from './StyledComponents';

export const LandingHowWeTeachTextEn = () => {
    return (
        <>
            <LandingSectionTitle
                initial={{ opacity: 0.5 }}
                whileInView={{ opacity: 1 }}
                viewport={{
                    margin: '0px 0px -30%',
                }}
            >
                With us you study{'\n'}
                <span>at a convenient time</span> for you{'\n'}and{' '}
                <span>at a pace convenient</span> for you
            </LandingSectionTitle>

            <LandingSectionTitle
                style={{ marginTop: 100 }}
                initial={{ opacity: 0.5 }}
                whileInView={{ opacity: 1 }}
                viewport={{
                    margin: '0px 0px -30%',
                }}
            >
                With us{'\n'}you will go through <span>more than 55 topics</span>
                {'\n'}and complete <span>more than 65 tasks to reinforce the theory</span>
            </LandingSectionTitle>
        </>
    );
};
