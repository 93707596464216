import { LandingSectionTitle } from './StyledComponents';

export const LandingHowWeTeachTextRu = () => {
    return (
        <>
            <LandingSectionTitle
                initial={{ opacity: 0.5 }}
                whileInView={{ opacity: 1 }}
                viewport={{
                    margin: '0px 0px -30%',
                }}
            >
                С нами вы учитесь{'\n'}
                <span>в удобное</span> для вас <span>время</span>
                {'\n'}и <span>в удобном</span> для вас <span>темпе</span>
            </LandingSectionTitle>

            <LandingSectionTitle
                style={{ marginTop: 100 }}
                initial={{ opacity: 0.5 }}
                whileInView={{ opacity: 1 }}
                viewport={{
                    margin: '0px 0px -30%',
                }}
            >
                С нами вы{'\n'}пройдёте <span>более 55 тем</span>
                {'\n'}и выполните <span>более 65 заданий для закреплении теории</span>
            </LandingSectionTitle>
        </>
    );
};
