import { DOMAttributes, FC, memo } from 'react';

import styled from 'styled-components';

import { Indent } from '@lib/theme';

type SeparatorProps = {
    width?: Indent | number;
    height?: Indent | number;
} & DOMAttributes<HTMLDivElement>;

const SeparatorContainer = styled.div<{
    width?: string;
    height?: string;
}>`
    ${(props) => (props.width ? `width: ${props.width};` : '')}
    ${(props) => (props.height ? `height: ${props.height};` : '')}
`;

const generateValueGetter = (indent: string | number | undefined) => {
    if (indent === undefined) {
        return undefined;
    }

    if (typeof indent === 'string') {
        return `var(--indent-${indent})`;
    }

    return `${indent}px`;
};

const Separator: FC<SeparatorProps> = (props) => {
    const { width, height, ...other } = props;

    const widthIndent = generateValueGetter(width);
    const heightIndent = generateValueGetter(height);

    return <SeparatorContainer {...other} width={widthIndent} height={heightIndent} />;
};

export default memo(Separator);
