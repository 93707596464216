import { useCallback, useState } from 'react';

import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';

import './index.scss';

import Separator from '@components/Common/Separator';

const Panel = (props) => {
    const [opened, setOpened] = useState(props.initialState || false);

    const togglePanel = useCallback(() => {
        setOpened((prev) => !prev);
    }, []);

    return (
        <div
            className={classNames('panel', props.panelClassName)}
            data-opened={opened}
            data-testid="collapse-container"
        >
            <div
                className={classNames('panel-title', props.panelTitleClassName)}
                onClick={togglePanel}
                data-testid="collapse-title"
            >
                <span>{props.title}</span>

                <Separator width="m" />

                <span>
                    <FontAwesomeIcon icon={opened ? faAngleUp : faAngleDown} />
                </span>
            </div>

            <AnimatePresence>
                {opened && (
                    <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: 'auto', opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className={classNames('panel-body', props.panelBodyClassName)}
                    >
                        <div className="panel-content">{props.children}</div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default Panel;
