import { motion } from 'framer-motion';
import { DemonstrationOfSortingWrapper } from 'guide/sorting/demonstrationOfSorting/DemonstrationOfSorting';
import styled from 'styled-components';

import {
    GuideContentWrapper,
    ModuleInfo,
} from '@components/Guide/Content/StyledComponents';
import { Link } from '@lib/i18n';

export const LandingContentWrapper = styled.div`
    position: relative;
    overflow: hidden;
    top: calc(-1 * var(--header-height) - 5px);
`;

export const LandingIntro = styled.section`
    position: relative;
    min-height: 100vh;
    padding-top: calc(var(--header-height));
`;

export const LandingIntroContent = styled.section`
    padding: var(--header-height) 20px 10px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
`;

export const LandingIntroTitle = styled.h1`
    letter-spacing: -0.08rem;
    font-size: 70px;
    line-height: 1.1em;
    font-weight: 700;
    background-clip: text;
    background-image: linear-gradient(52deg, #1d2645, #7c8cc0, #585d71);
    color: transparent;
    font-family: var(--font-700);
    letter-spacing: -2px;
    text-shadow: 1px 2px 6px #00000036;
    max-width: 800px;
    margin: 0px auto;
    text-align: center;

    @media screen and (max-width: 960px) {
        font-size: 40px;
    }
`;

export const LandingIntroDescription = styled.p`
    font-family: var(--font-300);
    font-size: 20px;
    line-height: 32px;
    max-width: 630px;
    margin: 35px auto 0px;
    text-align: center;

    @media screen and (max-width: 960px) {
        font-size: 18px;
        margin: 20px auto 0px;
    }
`;

export const LandingIntroPrint = styled.div`
    position: absolute;
    border-radius: inherit;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-repeat: repeat;
    background-position: left top;
    background-size: 24.5px auto;
    background-image: url(/static/print.png);
    z-index: -2;
    opacity: 0.55;
`;

export const LandingIntroBackgroundRadial = styled.div`
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;

    background-image: radial-gradient(
            circle at center center,
            rgb(0 0 0 / 0%),
            rgb(234 204 247 / 70%)
        ),
        repeating-radial-gradient(
            circle at center center,
            rgb(0 0 0 / 21%),
            rgb(0 0 0 / 31%),
            70px,
            transparent 140px,
            transparent 70px
        );
    opacity: 0.2;
    z-index: -3;
`;

export const LandingIntroBackgroundTone = styled.div`
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;

    background: radial-gradient(
        102.4% 100% at 75.5% -5.1%,
        #66ffd4 0.9009009009009009%,
        rgb(134 222 227) 34.08291103603604%,
        rgb(0 143 255 / 57%) 54.82650619369369%,
        rgb(0 177 255 / 35%) 100%
    );

    opacity: 0.4;
    z-index: -2;
`;

export const LandingIntroBackgroundBottomGradient = styled.div`
    position: absolute;
    left: 0px;
    right: 0px;
    top: 25vh;
    bottom: 0px;
    background: linear-gradient(0deg, white, transparent);
    z-index: -1;
`;

export const LandingIntroBlinkingOnlineStatus = styled.span`
    animation: blinker 4s linear infinite;
    width: 10px;
    height: 10px;
    display: inline-block;
    background-color: #4aed23;
    border-radius: 100%;
    box-shadow: 0px 0px 8px 3px #01ff00;
    transform: translateY(-42px);

    @keyframes blinker {
        50% {
            background-color: #196a05 !important;
            box-shadow: 0px 0px 8px 0px #1cba1b;
        }
    }

    @media screen and (max-width: 960px) {
        display: none;
    }
`;

export const LandingIntroReviews = styled.div`
    max-width: 630px;
    margin: 30px auto 0px;
    display: flex;
    justify-content: center;
    gap: 15px;
    align-items: center;
`;

export const LandingIntroReviewsAvatarList = styled.div`
    display: flex;
`;

export const LandingIntroReviewsAvatarWrapper = styled.div`
    width: 45px;
    height: 45px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    border: 1px solid #00000057;

    &:not(:first-child) {
        margin-left: -10px;
    }

    img {
        width: 45px;
        height: 45px;
        border-radius: 100%;
    }
`;

export const LandingIntroReviewsDescription = styled.div`
    display: flex;
    flex-direction: column;
`;

export const LandingIntroReviewsDescriptionRating = styled.div`
    display: flex;
    gap: 3px;

    svg {
        fill: #ff9800;
        color: #ff9800;
    }
`;
export const LandingIntroReviewsDescriptionText = styled.p`
    margin: 6px 0px;
`;

export const LandingIntroNavigation = styled.div`
    max-width: 960px;
    margin: 65px auto 0px;
    display: flex;
    justify-content: center;
    gap: 15px;
    align-items: center;

    @media screen and (max-width: 960px) {
        flex-direction: column;
    }
`;

export const NavigationItemAngle = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 26px;
    right: 23px;
    font-size: 1em;
    background: #ffffff45;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    transition: 0.3s ease;
`;

export const NavigationItem = styled(Link)`
    cursor: pointer;
    position: relative;
    border-radius: 20px;
    box-sizing: border-box;
    transition: 0.3s ease;
    transition-property: box-shadow;
    max-height: 300px;
    box-shadow: 6px 9px 10px rgb(33 33 33 / 10%);
    text-decoration: none;
    max-width: 380px;

    &:hover {
        box-shadow: 6px 9px 30px rgb(33 33 33 / 40%);
    }

    &:hover::before {
        filter: brightness(1);
    }

    &:hover ${NavigationItemAngle} {
        background: #ffffff6e;
        right: 15px;
    }

    &::before {
        position: absolute;
        border-radius: 20px;
        content: '';
        top: 0px;
        width: 100%;
        height: 100%;
        filter: blur(1px);
        transition: 0.3s ease;
    }

    @media screen and (max-width: 960px) {
        width: 100% !important;
    }
`;

export const NavigationItemInfo = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    color: var(--white);
`;

export const NavigationItemTitle = styled.h2`
    display: block;
    padding: 20px 25px 0px;
    font-family: var(--font-700);
    font-size: 20px;
    margin: 0px;
`;

export const NavigationItemDescription = styled.div`
    padding: 15px 25px 25px;
    font-size: 15px;
    line-height: 23px;
    color: #eee;
`;

export const NavigationCourse = styled(NavigationItem)`
    &::before {
        filter: brightness(0.9);
        background: linear-gradient(312deg, #e39fff, #d158ffde, #a932d6cf, #79429bc9);
    }
`;

export const NavigationTrainer = styled(NavigationItem)`
    &::before {
        filter: brightness(0.9);
        background: linear-gradient(to bottom right, #41e3b6ab, #5390d9c4);
    }
`;

export const NavigationTelegram = styled(NavigationItem)`
    min-width: 200px;

    &::before {
        filter: brightness(0.9);
        background: linear-gradient(324deg, #47bcfd, #098fd2);
    }
`;

export const LandingForWhom = styled.section`
    position: relative;
`;

export const LandingForWhomWrapper = styled.div`
    margin: 0px auto;
    max-width: 1200px;
`;

export const LandingForWhomContent = styled(motion.div)`
    margin: 0px;
    background: radial-gradient(
        102.4% 100% at 75.5% -5.1%,
        #84d9c1 0.9009009009009009%,
        rgb(128 196 200) 34.08291103603604%,
        rgb(118 160 208) 54.82650619369369%,
        rgb(25 24 24 / 90%) 100%
    );
    border-radius: var(--border-radius-xxl);
    box-sizing: border-box;
    padding: 40px;
    box-shadow: 6px 9px 12px 10px rgb(33 33 33 / 10%);

    @media screen and (max-width: 960px) {
        padding: 40px 20px 20px;
    }
`;

export const LandingForWhomTitle = styled.h3`
    font-family: var(--font-700);
    font-size: 35px;
    color: var(--white);
    margin: 0px 5px;

    @media screen and (max-width: 960px) {
        font-size: 26px;
    }
`;

export const LandingForWhomList = styled.div`
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;

    @media screen and (max-width: 1200px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 660px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

export const LandingForWhomListItem = styled.div`
    position: relative;
    background: var(--white);
    border-radius: 15px;
    padding: 25px;
`;

export const LandingForWhomListItemHeader = styled.div`
    font-size: 18px;
`;

export const LandingForWhomListItemIcon = styled.div`
    position: absolute;
    right: 20px;
    top: 20px;
    transform: rotate(15deg);
    color: #6a8cb2;
`;

export const LandingForWhomListItemDescription = styled.div`
    font-size: 15px;
    margin-top: 15px;
    line-height: 20px;
`;

export const LandingHowWeTeach = styled.section`
    position: relative;
    margin: 150px auto;
    max-width: 1200px;

    @media screen and (max-width: 960px) {
        margin: 100px auto 0px;
    }
`;

export const LandingSectionTitle = styled(motion.p)`
    font-family: var(--font-700);
    font-size: 40px;
    margin: 8px 20px;
    max-width: calc(50% - 30px);
    white-space: pre-wrap;
    line-height: 1.4;

    span {
        color: #4199ff;
    }

    @media screen and (max-width: 960px) {
        max-width: 100%;
        white-space: unset;
        font-size: 34px;
    }
`;

export const LandingHowWeTeachTitle = styled(motion.p)`
    font-family: var(--font-700);
    font-size: 40px;
    margin: 8px 20px;
    max-width: calc(50% - 30px);
    white-space: pre-wrap;
    line-height: 1.4;

    span {
        color: #4199ff;
    }

    @media screen and (max-width: 960px) {
        max-width: 100%;
        white-space: unset;
        font-size: 34px;
    }
`;

export const LandingHowWeTeachContent = styled.div`
    padding-bottom: 100px;
`;

export const LandingHowWeTeachPlan = styled(motion.div)`
    position: absolute;
    height: 100%;
    overflow: hidden;
    right: calc(-1 * (100vw - 1200px) / 2 - 30px);
    top: 0px;
    width: calc(50% + (100vw - 1200px) / 2);
    background: radial-gradient(
        102.4% 100% at 75.5% -5.1%,
        #84d9c1 0.9009009009009009%,
        rgb(128 196 200) 34.08291103603604%,
        rgb(118 160 208) 54.82650619369369%,
        rgb(25 24 24 / 90%) 100%
    );
    padding: 4px;
    box-sizing: border-box;
    border-radius: var(--border-radius-xxl);

    ${GuideContentWrapper} {
        margin: 0px;

        grid-template-columns: 1fr;
    }

    ${ModuleInfo} {
        display: none;
    }

    @media screen and (max-width: 960px) {
        display: none;
    }
`;

export const LandingHowWeGuideContentWrapper = styled.div`
    height: 100%;
    overflow: hidden;
    border-radius: 20px;
    background: var(--white);
`;

export const LandingDemo = styled.section`
    position: relative;
    margin: 150px auto;
    max-width: 1200px;

    @media screen and (max-width: 960px) {
        margin: 0px auto 0px;
    }
`;

export const LandingDemoTitle = styled(motion.p)`
    font-family: var(--font-700);
    font-size: 40px;
    margin: 8px 20px;

    span {
        color: #4199ff;
    }

    @media screen and (max-width: 960px) {
        font-size: 34px;
    }
`;

export const LandingDemoContent = styled(motion.div)`
    background: var(--white);
    margin: 0px 20px;

    @media screen and (max-width: 960px) {
        margin: 0px;
    }

    ${DemonstrationOfSortingWrapper} {
        grid-template-columns: minmax(auto, 450px) auto !important;

        @media screen and (max-width: 860px) {
            grid-template-columns: auto !important;
        }
    }
`;

export const LandingTrainer = styled.section`
    position: relative;
    margin-top: 150px;
    background: radial-gradient(
        102.4% 100% at 75.5% -5.1%,
        #84d9c1 0.9009009009009009%,
        rgb(128 196 200) 34.08291103603604%,
        rgb(118 160 208) 54.82650619369369%,
        rgb(25 24 24 / 90%) 100%
    );
    box-shadow: 6px 9px 12px 10px rgb(33 33 33 / 10%);
`;

export const LandingTrainerWrapper = styled.section`
    margin: 0px auto;
    max-width: 1200px;
    box-sizing: border-box;
    padding: 100px 20px;

    @media screen and (max-width: 960px) {
        padding: 40px 20px;
    }
`;

export const LandingTrainerTitle = styled.h3`
    font-family: var(--font-700);
    font-size: 35px;
    margin: 0px;
    color: var(--white);

    @media screen and (max-width: 960px) {
        font-size: 34px;
    }
`;

export const LandingTrainerContent = styled.a`
    display: block;
    text-decoration: none;
    margin-top: 40px;

    video {
        width: 100%;
        border-radius: 20px;
        border: 8px solid #07070761;
        box-sizing: border-box;
        mask: radial-gradient(
            125% 82.92682926829269% at 22.3% 15.8%,
            #000000 0%,
            rgba(0, 0, 0, 0.7) 50%,
            rgba(0, 0, 0, 0) 100%
        );
    }
`;

export const LandingTrainerVideoContent = styled.div`
    position: absolute;
    bottom: calc(50% - 85px);
    height: 85px;
    right: 50%;
    transform: translateX(50%);
    width: 320px;
    background: linear-gradient(116deg, #ffffffb5, #ffffffc4, #ffffff45);
    border-radius: 50px;
    padding: 30px;
    box-sizing: border-box;
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease;
    border: 1px solid #00000042;

    @media screen and (max-width: 640px) {
        bottom: calc(50% - 160px);
    }
`;

export const LandingTrainerVideoContentTitle = styled.div`
    font-size: 20px;
    margin: 0px;
    color: var(--text-color-primary);
    opacity: 0.8;
`;

export const LandingTrainerVideo = styled.div`
    position: relative;
    cursor: pointer;

    video {
        width: 100%;
        border-radius: 20px;
        border: 8px solid #07070761;
        box-sizing: border-box;
        box-shadow: 6px 9px 12px 10px rgb(33 33 33 / 50%);
        transition: 0.3s ease;
    }

    ${NavigationItemAngle} {
        color: var(--white);
        background: #3333334d;
    }

    &:hover {
        video {
            filter: brightness(0.6);
        }
    }

    &:hover ${LandingTrainerVideoContent} {
        background: linear-gradient(116deg, #ffffffd6, #ffffffe8, #ffffff80);
    }

    &:hover ${NavigationItemAngle} {
        transform: rotate(-45deg);
        background: #33333367;
    }

    &:hover ${LandingTrainerVideoContentTitle} {
        opacity: 1;
    }
`;

export const LandingTrainerBenefits = styled.div`
    display: flex;
    margin-top: 80px;
    justify-content: center;
    gap: 30px;

    @media screen and (max-width: 960px) {
        flex-direction: column;
        align-items: center;
    }
`;

export const LandingTrainerBenefit = styled(motion.div)`
    display: flex;
    max-width: 300px;
    gap: 12px;
    font-size: 17px;
    line-height: 20px;
    font-family: var(--font-300);
    letter-spacing: 0.4px;
    color: #fff;
`;

export const LandingInterviewTasks = styled.section`
    position: relative;
    max-width: 1200px;
    margin: 150px auto 0px;
`;

export const LandingInterviewTasksTitle = styled.h3`
    font-family: var(--font-700);
    font-size: 25px;
    margin: 0px;
    text-align: center;
`;

export const LandingInterviewTasksContent = styled.div`
    overflow: hidden;
    position: relative;
    overflow: hidden;
    align-items: center;
    margin-top: 40px;

    --no-of-slides: 12;
    --slides-in-view: 8;
    --slide-width: 200px;
    --slide-height: 55px;
    --slide-offset: 50px;
    --iteration-time: 40s;

    &::before,
    &::after {
        background: linear-gradient(
            to right,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0) 100%
        );

        content: '';
        height: 100px;
        position: absolute;
        width: 200px;
        z-index: 2;
    }

    &::after {
        right: 0;
        top: 0;
        transform: rotateZ(180deg);
    }

    &::before {
        left: 0;
        top: 0;
    }

    @keyframes scroll {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(calc(var(--slide-width) * var(--no-of-slides) * -1));
        }
    }
`;

export const LandingInterviewTasksList = styled.div`
    display: flex;
    align-items: center;
    width: calc(var(--slides-in-view) * var(--slide-width));
    overflow: hidden;
    margin: 0 auto;
`;

export const LandingInterviewTaskCompany = styled.div`
    animation: scroll var(--iteration-time) linear infinite;
    display: flex;
    min-width: var(--slide-width);
    height: var(--slide-height);
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    padding-right: var(--slide-offset);

    img {
        max-width: calc(var(--slide-width) - var(--slide-offset));
        max-height: 50px;
        border-radius: 5px;
    }
`;

export const LandingCertificate = styled.section`
    position: relative;
    max-width: 1200px;
    margin: 150px auto 0px;
    overflow-y: clip;
    max-height: 840px;
`;

export const LandingCertificateTitle = styled.h3`
    font-family: var(--font-700);
    font-size: 40px;
    margin: 0px;
    text-align: center;

    @media screen and (max-width: 960px) {
        font-size: 34px;
    }
`;

export const LandingCertificateDescription = styled.p`
    font-family: var(--font-300);
    font-size: 18px;
    margin: 20px 0px;
    text-align: center;
`;

export const LandingCertificateContent = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    margin: 80px 0px -33px;

    img {
        max-width: 1000px;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 6px 9px 12px 10px rgb(33 33 33 / 10%);
    }

    @media screen and (max-width: 960px) {
        margin-top: 40px;
    }
`;

export const LandingCertificateImageBackdrop = styled(motion.img)`
    position: absolute;
    opacity: 0.9;
    z-index: -1;

    @media screen and (max-width: 960px) {
        display: none;
    }
`;

export const LandingReview = styled.section`
    position: relative;
    max-width: 1200px;
    margin: 150px auto 0px;
`;

export const LandingReviewTitle = styled.p`
    display: flex;
    font-family: var(--font-700);
    font-size: 40px;
    margin: 0px 20px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    align-items: center;
    white-space: nowrap;

    svg {
        color: var(--primary-red);
        fill: var(--primary-red);
    }

    @media screen and (max-width: 960px) {
        font-size: 26px;
    }
`;

export const LandingReviewColumns = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    margin: 80px 20px;
    gap: 15px;

    @media screen and (max-width: 960px) {
        flex-direction: column;
        margin: 40px 0px;
    }
`;

export const LandingReviewRow = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    flex: 1;
`;

export const LandingReviewItem = styled(motion.div)`
    border-radius: 15px;
    padding: 25px;
    background: linear-gradient(5deg, #262626, #505050);
`;

export const LandingReviewItemHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const LandingReviewItemHeaderAvatar = styled.div`
    img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 1px solid;
    }
`;

export const LandingReviewItemHeaderName = styled.div`
    color: #fffffff0;
`;

export const LandingReviewItemText = styled.div`
    margin-top: 15px;
    line-height: 24px;
    color: #dddddd;
    font-size: 16px;
    white-space: break-spaces;
`;

export const LandingFAQ = styled.section`
    position: relative;
    max-width: 1200px;
    margin: 150px auto 0px;

    @media screen and (max-width: 960px) {
        margin: 80px auto 0px;
    }
`;

export const LandingFAQTitle = styled.p`
    font-family: var(--font-700);
    font-size: 40px;
    margin: 0px;
    text-align: center;
`;

export const LandingFAQContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 40px auto;
    max-width: 760px;

    .panel {
        .panel-title {
            margin: 0px 20px;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            padding: 18px 0px;
            font-size: 22px;
            color: #444;
            transition: 0.3s ease;
            user-select: none;

            font-family: var(--font-700);
        }

        .panel-content {
            margin: 0px 20px;
            padding: 10px 0px 20px;
            line-height: 26px;
            font-size: 18px;

            p:first-child {
                margin-top: 0;
            }

            p:last-child {
                margin-bottom: 0;
            }

            li {
                margin-bottom: 10px;
            }
        }
    }
`;
