import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { faCheck, faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clipboardCopy from 'clipboard-copy';

import { IconButton, IconButtonProps } from '@components/Common/Button';

type CopyButtonProps = {
    caption: string;
    onCopy?: (value: string) => void;
    delay?: number;
};

const CopyButton: FC<CopyButtonProps & IconButtonProps> = ({
    caption,
    onCopy,
    delay = 1000,
    ...other
}) => {
    const [isCopied, setIsCopied] = useState(false);
    const timerRef = useRef<NodeJS.Timeout | undefined>(undefined);

    const stopTimer = () => {
        clearTimeout(timerRef.current);
    };

    const startTimer = () => {
        timerRef.current = setTimeout(() => setIsCopied(false), delay);
    };

    const onClick = useCallback(async () => {
        await clipboardCopy(caption);

        setIsCopied(true);

        stopTimer();
        startTimer();

        onCopy && onCopy(caption);
    }, [caption, onCopy]);

    useEffect(stopTimer, []);

    return (
        <IconButton
            size="small"
            kind="secondary"
            icon={<FontAwesomeIcon icon={isCopied ? faCheck : faCopy} />}
            onClick={onClick}
            bordered
            shape="circled"
            style={{ fontSize: '0.7em' }}
            {...other}
        />
    );
};

export default CopyButton;
