import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IconButton } from '@components/Common/Button';
import { createShareLink } from '@lib/utils';

const ExecuteQueryButton = ({ sql, database }) => {
    const sandboxUrl = createShareLink({
        origin: '/sandbox',
        sql,
        database,
    });

    const openSandbox = (url) => window.open(url, '_blank');

    return (
        <IconButton
            size="small"
            icon={<FontAwesomeIcon icon={faPlay} style={{ width: 8, height: 8 }} />}
            onClick={() => openSandbox(sandboxUrl)}
            shape="circled"
            bordered
        />
    );
};

export default ExecuteQueryButton;
