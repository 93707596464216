import { ComponentType, FC, PropsWithChildren } from 'react';

import classnames from 'classnames';
import { motion, MotionProps } from 'framer-motion';
import Refractor from 'react-refractor';
import sql from 'refractor/lang/sql.js';

import Separator from '../Separator';

import CopyButton from './CopyButton';
import ExecuteQueryButton from './ExecuteQueryButton';

import './index.scss';
import './prism.scss';

type BlockCodeProps = {
    theme?: 'bold' | 'light';
    dbms?: string;
    executable?: boolean;
    database?: string;
    copyable?: boolean;
    children: string;
    className?: string;
    showEnv?: boolean;
    sharedSql?: string;
} & Partial<MotionProps>;

type InlineCodeProps = PropsWithChildren<{
    color?: 'blue';
    elementType?: string;
    className?: string;
}>;

Refractor.registerLanguage(sql);

const InlineCode: FC<InlineCodeProps> = ({
    color = 'blue',
    elementType = 'span',
    children,
}) => {
    const Tag = elementType as unknown as ComponentType<
        PropsWithChildren<{ className: string }>
    >;

    return (
        <Tag
            className={classnames([
                'sql-code',
                'sql-code-line',
                `sql-code-line--${color}-color `,
            ])}
        >
            {children}
        </Tag>
    );
};

const BlockCode: FC<BlockCodeProps> = ({
    children,
    dbms = 'MySQL',
    executable = false,
    database,
    copyable = true,
    className,
    showEnv = true,
    sharedSql,
    ...other
}) => {
    return (
        <motion.div className="sql-code-block-wrapper" {...other}>
            <div className="not-printable sql-code-block-toolbar">
                {showEnv && <div className="env">{dbms}</div>}

                {copyable && <CopyButton caption={children} />}

                {copyable && executable && <Separator width="s" />}

                {executable && (
                    <ExecuteQueryButton sql={sharedSql || children} database={database} />
                )}
            </div>

            <Refractor
                inline={false}
                language="sql"
                value={children}
                className={classnames(['sql-code', 'sql-code-block', 'prism', className])}
            />
        </motion.div>
    );
};

export { InlineCode, BlockCode };
