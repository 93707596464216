import typeDetect from 'type-detect';

const formatValueToString = (value: unknown): string => {
    const valueType = typeDetect(value);

    switch (valueType) {
        case 'null':
            return '<NULL>';

        default:
            return value as string;
    }
};

export { formatValueToString };
