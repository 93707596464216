
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { FC } from 'react';
import { GetStaticProps } from 'next';
import { getGuideModules, getLandingStats } from '@api/guide';
import Footer from '@components/Common/Footer';
import Header from '@components/Common/Header';
import { Meta } from '@components/Common/Meta';
import LandingContent from '@components/Landing/Landing';
import { IModule } from '@interfaces/guide';
import { useLoadCommonData } from '@lib/dataLoaders/client';
import { useTranslation } from '@lib/i18n';
interface IProps {
    modules: IModule[];
    landingStats: {
        amountUsers: number;
        amountSubmissions: number;
    };
}
const Index: FC<IProps> = ({ modules, landingStats }) => {
    const { t } = useTranslation('indexPage');
    useLoadCommonData();
    return (<div>
            <Meta title={t('meta.title')} description={t('meta.description')}/>

            <Header isTransparentBg/>

            <main>
                <LandingContent modules={modules} landingStats={landingStats}/>
            </main>

            <Footer />
        </div>);
};
const getStaticProps: GetStaticProps<{
    modules: IModule[];
}> = async function (ctx) {
    const locale = ctx.locale;
    const { data: modules } = await getGuideModules(undefined, {
        headers: {
            Cookie: `NEXT_LOCALE=${locale};`,
        },
    });
    const { data: landingStats } = await getLandingStats();
    return {
        props: {
            modules,
            landingStats,
        },
        revalidate: 60 * 60 * 12,
    };
};
export default Index;

    async function __Next_Translate__getStaticProps__193b58395b7__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__193b58395b7__ as getStaticProps }
  