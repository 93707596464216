import { useRef, useEffect, FC, PropsWithChildren } from 'react';

import classNames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';

import './index.scss';

type WildcardKeys<T> = T & { [key: string]: unknown };

type ScrollableContainerRef = Scrollbars;

type ScrollableContainerProps = PropsWithChildren<
    WildcardKeys<{
        getRef?: (ref: Scrollbars | null) => void;
        className?: string;
    }>
>;

const ScrollableContainer: FC<ScrollableContainerProps> = ({
    children,
    getRef,
    className,
    ...other
}) => {
    const scrollRef = useRef<Scrollbars | null>(null);

    useEffect(() => {
        getRef && getRef(scrollRef.current);
    }, [getRef]);

    return (
        <Scrollbars
            ref={scrollRef}
            className={classNames('scrollable-container-wrapper', className)}
            {...other}
        >
            {children}
        </Scrollbars>
    );
};

export type { ScrollableContainerRef };
export default ScrollableContainer;
