import { IDatabase } from './databases';

export enum IQuestionDifficulty {
    EASY = 'easy',
    MEDIUM = 'medium',
    HARD = 'hard',
}

export interface IQuestion {
    id: number;
    _id: string;
    question: string;
    rightAnswer: string;
    isSolved: boolean;
    database: IDatabase;
    additional_info: string[];
    fields: string[];
    difficulty: IQuestionDifficulty;
    initialAnswerValue?: string;
    isRevealed?: boolean;
    isSolvedWithRevealing?: boolean;
    answerFormat?: {
        fields: string[];
        rows: object[];
    };
    premiumOnly?: boolean;
    companyName?: string;
}

export interface IQuestionAnswer {
    answer: string;
}
