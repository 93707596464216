import {
    FC,
    ReactNode,
    CSSProperties,
    useEffect,
    ComponentPropsWithoutRef,
    HTMLProps,
} from 'react';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import ReactTooltip from 'react-tooltip';
import styled, { css } from 'styled-components';

import Separator from '../Separator';

import theme from './theme';

type BaseStyleProps = {
    size: 'big' | 'medium' | 'small' | 'xs';
    kind: 'primary' | 'secondary' | 'link';
    shape: 'rounded' | 'circled';
    shadow: boolean;
    bordered: boolean;
    theme: typeof theme;
};

type ButtonProps = {
    left?: ReactNode;
    right?: ReactNode;
    children: ReactNode;
    loading?: boolean;
    className?: string;
    style?: CSSProperties;
    'data-tip'?: string;
    'data-place'?: string;
} & Omit<Partial<BaseStyleProps>, 'theme'> &
    Pick<HTMLProps<HTMLButtonElement>, 'onClick' | 'disabled'>;

export type IconButtonProps = Omit<ButtonProps, 'children'> & {
    icon?: ReactNode;
};

const buttonBoxShadow = (
    { bordered, shadow, kind, theme }: BaseStyleProps,
    state = 'default',
) => {
    if (bordered) {
        return theme.border[kind];
    }

    return shadow ? theme.boxShadow[state] : 'none';
};

const baseButtonStyle = css<BaseStyleProps>`
    box-sizing: border-box;

    text-align: center;
    transition: 0.2s ease-in-out;
    transition-property: background, box-shadow, color, font-size, opacity;
    border: none;
    font-family: var(--font-500);
    box-shadow: ${(props) => buttonBoxShadow(props)};
    cursor: pointer;
    color: ${(props) => props.theme.color[props.kind]};
    font-size: ${(props) => props.theme.fontSize[props.size]};
    background: ${(props) => props.theme.background[props.kind].default};
    border-radius: ${(props) => props.theme.borderRadius[props.shape]};
    position: relative;

    @media (hover: hover) {
        &:hover {
            box-shadow: ${(props) => buttonBoxShadow(props, 'hover')};
            background: ${(props) => props.theme.background[props.kind].hover};
        }
    }

    @media (hover: none) {
        &:focus:not(.focus-visible) {
            box-shadow: ${(props) => buttonBoxShadow(props, 'hover')};
            background: ${(props) => props.theme.background[props.kind].hover};
        }
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: all !important;
        background: ${(props) => props.theme.background[props.kind].default};
    }
`;

const StyledButton = styled.button`
    ${baseButtonStyle}

    padding: 0;
`;

const StyledIconButton = styled.button`
    ${baseButtonStyle}

    padding: 0;

    height: ${(props) => props.theme.measurements[props.size].height};
    width: ${(props) => props.theme.measurements[props.size].height};
`;

const TooltipContainer = styled.div<Partial<Pick<BaseStyleProps, 'size' | 'kind'>>>`
    padding: ${(props) =>
        props.size && props.kind !== 'link' ? props.theme.padding[props.size] : 0};

    width: 100%;
    box-sizing: border-box;
`;

const LoadingContainer = styled(motion.div)`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ButtonContentContainer = styled(motion.div)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Button: FC<ButtonProps & ComponentPropsWithoutRef<'button'>> = (props) => {
    const {
        left = null,
        right = null,
        loading = false,
        children = null,
        size = 'medium',
        kind = 'primary',
        shape = 'rounded',
        shadow = false,
        bordered = false,
        'data-tip': dataTip,
        'data-place': dataPlace,
        ...other
    } = props;

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [dataTip]);

    return (
        <StyledButton
            theme={theme}
            type="button"
            size={size}
            kind={kind}
            shape={shape}
            shadow={shadow}
            bordered={bordered}
            {...other}
        >
            <TooltipContainer
                size={size}
                theme={theme}
                kind={kind}
                data-tip={dataTip}
                data-place={dataPlace}
            >
                <ButtonContentContainer
                    animate={loading ? 'hidden' : 'show'}
                    initial={false}
                    transition={{ duration: 0.1 }}
                    variants={{
                        hidden: { opacity: 0 },
                        show: { opacity: 1 },
                    }}
                >
                    {left}
                    {left && <Separator width="m" />}

                    {children}

                    {right && <Separator width="m" />}
                    {right}
                </ButtonContentContainer>

                <AnimatePresence>
                    {loading && (
                        <LoadingContainer
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.1 }}
                        >
                            <FontAwesomeIcon icon={faSpinner} spin />
                        </LoadingContainer>
                    )}
                </AnimatePresence>
            </TooltipContainer>
        </StyledButton>
    );
};

const IconButton: FC<IconButtonProps & ComponentPropsWithoutRef<'button'>> = (props) => {
    const {
        icon,
        size = 'medium',
        kind = 'primary',
        shape = 'rounded',
        shadow = false,
        bordered = false,
        loading = false,
        'data-tip': dataTip,
        'data-place': dataPlace,
        ...other
    } = props;

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [dataTip]);

    return (
        <StyledIconButton
            type="button"
            aria-label="Copy SQL"
            theme={theme}
            size={size}
            kind={kind}
            shape={shape}
            shadow={shadow}
            bordered={bordered}
            {...other}
        >
            <TooltipContainer
                css={css`
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                `}
                data-tip={dataTip}
                data-place={dataPlace}
            >
                <ButtonContentContainer
                    animate={loading ? 'hidden' : 'show'}
                    initial={false}
                    transition={{ duration: 0.1 }}
                    variants={{
                        hidden: { opacity: 0 },
                        show: { opacity: 1 },
                    }}
                >
                    {icon}
                </ButtonContentContainer>

                <AnimatePresence>
                    {loading && (
                        <LoadingContainer
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.1 }}
                        >
                            <FontAwesomeIcon icon={faSpinner} spin />
                        </LoadingContainer>
                    )}
                </AnimatePresence>
            </TooltipContainer>
        </StyledIconButton>
    );
};

export { Button, IconButton };
export type { ButtonProps };
